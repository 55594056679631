import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import ClosedCaptionRoundedIcon from "@mui/icons-material/ClosedCaptionRounded";
import { parseText } from "../textToHTMLParser.js";

const TranscriptionContent = ({
  video,
  selectedTab,
  handleTranscribeVideo,
  transcriptionInProgress,
}) => {
  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  const colors = [
    "#fef2e9",
    "#faeded",
    "#f6ebf1",
    "#f0ecf5",
    "#e9f1f8",
    "#e7f5f6",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 500);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
        marginBottom: "16px",
        height:
          video?.transcriptions[selectedTab]?.text.length > 0
            ? "calc(100% - 50px)"
            : "100%",
        padding: "15px 5px",
      }}
    >
      {video?.transcriptions[selectedTab]?.text.length > 0 ? (
        <div
          style={{
            fontSize: "13px",
            whiteSpace: "pre-wrap",
            lineHeight: "1.5",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: parseText(video?.transcriptions[selectedTab]?.text),
            }}
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            gap: "5px",
            flexDirection: "row",
            color: "grey",
          }}
        >
          <Typography>No transcription</Typography>
          <Tooltip title="Transcribe" arrow placement="bottom">
            <span>
              <IconButton
                style={{
                  backgroundColor: colors[currentColorIndex],
                  padding: "7px",
                }}
                disabled={transcriptionInProgress}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleTranscribeVideo();
                }}
              >
                <ClosedCaptionRoundedIcon
                  style={{ color: "#000", fontSize: "17px" }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
    </div>
  );
};

export default TranscriptionContent;
