export const parseText = (text) => {
  let html = text
    .replace(/^### (.+)$/gm, "<h3>$1</h3>")
    .replace(/^## (.+)$/gm, "<h2>$1</h2>")
    .replace(/^# (.+)$/gm, "<h1>$1</h1>")
    .replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>")
    .replace(/^- (.+)$/gm, "<ul><li>$1</li></ul>")
    .replace(/^\n/gm, "<br/>")
    .replace(/^\n{2,}/g, "</p><p>")
    .replace(/\[([^[]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>')
    .replace(/```([^`]+)```/gs, "<pre>$1</pre>")
    .replace(/`([^`]+)`/g, "<code>$1</code>")
    .replace(/^\n/gm, "<br/>")
    .replace(/\n{2,}/g, "</p><p>")
    .replace(/(?:^|\n)(\|.*\|)\s*(?=\n|$)/g, (match) => {
      const rows = match
        .trim()
        .split("\n")
        .map((row) => {
          const cells = row
            .split("|")
            .filter(Boolean)
            .map((cell) => cell.trim());
          return `<tr>${cells
            .map(
              (cell) =>
                `<td style="padding: 5px; border: 1px solid black; width: ${
                  100 / cells.length
                }%; height: 100%;">${cell}</td>`
            )
            .join("")}</tr>`;
        })
        .join("");
      return `<table style="width: 100%; display: table; table-layout: fixed; border-collapse: collapse;">${rows}</table>`;
    })
    .replace(/<\/ul><ul>/g, "");

  return html;
};
